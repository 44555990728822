var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-preview"},[_c('div',{staticClass:"media-header"},[_c('div',{staticClass:"d-flex justify-content-start align-items-center"},[_c('div',[_c('h1',{staticClass:"d-flex justify-content-start align-items-center"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.subtitle)+" ")])])]),_c('div',[(_vm.hasNavigation)?_c('div',{staticClass:"btn-group mr-4"},[_c('button',{staticClass:"btn bg-muted border border-default-dark shadow-sm btn-sm",attrs:{"disabled":!_vm.hasNavigation.previous},on:{"click":function($event){return _vm.navigate('previous')}}},[_c('i',{staticClass:"fas fa-angle-left"})]),_c('button',{staticClass:"btn bg-muted border border-default-dark shadow-sm btn-sm",attrs:{"disabled":!_vm.hasNavigation.next},on:{"click":function($event){return _vm.navigate('next')}}},[_c('i',{staticClass:"fas fa-angle-right"})])]):_vm._e(),_c('button',{staticClass:"btn border shadow-sm btn-close",on:{"click":function($event){return _vm.closePreview()}}},[_vm._v(" × ")])])]),_c('div',{staticClass:"media-container p-0"},[(!_vm.media || !_vm.media.url)?_c('div',{staticClass:"placeholder"},[_c('span',{staticClass:"text-muted"},[_vm._v("No preview available")])]):(_vm.isLoading)?_c('div',{staticClass:"placeholder"},[_c('div',{staticClass:"spinner-border text-primary mr-3"}),_c('span',{staticClass:"text-muted"},[_vm._v("Loading preview...")])]):_vm._e(),_c('div',{staticClass:"media-preview-osd",class:{
        invisible: _vm.isLoading || !_vm.media || !_vm.media.mime.includes('image'),
      },attrs:{"id":"osd"}},[_vm._m(0)]),_c('div',{staticClass:"media-preview-osd",class:{
        invisible: _vm.isLoading || !_vm.media || !_vm.media.mime.includes('video'),
      }},[_vm._m(1)]),_c('div',{staticClass:"media-preview-osd",class:{
        invisible: _vm.isLoading || !_vm.media || !_vm.media.mime.includes('gif'),
      }},[_c('img',{staticClass:"gif",attrs:{"src":_vm.media.mime.includes('gif') ? _vm.media.url : ''}})]),_c('div',{staticClass:"media-preview-osd",class:{
        invisible:
          _vm.isLoading ||
          !_vm.media ||
          (_vm.media.mime !== 'iframe' && !_vm.media.mime.includes('pdf')),
      }},[_c('iframe',{attrs:{"src":_vm.media.mime == 'iframe' || _vm.media.mime.includes('pdf')
            ? _vm.media.url
            : ''}})])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"osd-buttons"},[_c('button',{staticClass:"btn bg-muted border border-secondary text-dark shadow-sm",attrs:{"id":"osd-zoom-in"}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"btn bg-muted border border-secondary text-dark shadow-sm",attrs:{"id":"osd-zoom-out"}},[_c('i',{staticClass:"fas fa-minus"})]),_c('button',{staticClass:"btn bg-muted border border-secondary text-dark shadow-sm",attrs:{"id":"osd-home"}},[_c('i',{staticClass:"fas fa-home"})]),_c('button',{staticClass:"btn bg-muted border border-secondary text-dark shadow-sm",attrs:{"id":"osd-full-page"}},[_c('i',{staticClass:"fas fa-expand"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('video',{attrs:{"id":"videoPlayer","controls":""}},[_c('source',{attrs:{"id":"videoSource"}})])}]

export { render, staticRenderFns }